<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-model="getModalViewPaymentMethod"
  >
    <v-card dark class="expande-horizontal wrap">
      <div class="expande-horizontal px-6 py-3">
        <v-btn dark @click="closeModalViewPaymentMethod" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt;" class="fonte white--text">
          Método de Pagamento
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="closeModalViewPaymentMethod">
          <v-icon color="red darken-3">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Nome
              </span>
              <v-text-field
                autofocus
                v-model="getPaymentMethod.name"
                :rules="[v=> !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Descrição
              </span>
              <v-text-field
                v-model="getPaymentMethod.description"
                :rules="[v=> !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Informações de Pagamento
              </span>
              <v-textarea
                @keyup.enter="validate"
                v-model="getPaymentMethod.info"
                :rules="[v=> !!v || 'preencha aqui']"
                dense
                outlined
                solo
                :color="$theme.primary"
              ></v-textarea>
            </v-flex>
            
            <!-- <v-flex class="px-7 pb-6" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Imagens do produto
              </span>
              <SendArchive />
            </v-flex> -->
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="validate" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SendArchive from '@/apps/shared/components/ModalSendArchive'
export default {
  components: {
      SendArchive
  },
  computed: {
    ...mapGetters(["getModalViewPaymentMethod", "getPaymentMethod"])
  },
  methods: {
    ...mapActions(["createPaymentMethod", "updatePaymentMethod",  "closeModalViewPaymentMethod"]),
    validate() {
      if (this.$refs.form.validate()) {
        if(this.getPaymentMethod._id) {
          this.updatePaymentMethod()
        } else {
          this.createPaymentMethod();
        }
      }
    }
  }
};
</script>
