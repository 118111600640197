<template>
  <layout :title="$route.name" :options="options">
    <div style="min-height: 80vh" class="pa-2">

      <v-simple-table
        dense
        class="item-table ma-2"
        v-show="$vuetify.breakpoint.lgAndUp"
        v-if="getPaymentMethods.length > 0"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>NOME</th>
              <th>DESCRIÇÃO</th>
              <th></th>
              <th>Excluir</th>
              <!-- <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in getPaymentMethods"
              :key="item._id"
              class="animated fadeInUp item-table"
              :class="index % 2 === 0 ? 'bg-table' : ''"
            >
              <!-- <td @input="verificaSelecionados($event)" style="width: 20px;" :class="item.selected ? 'bg-selected' : ''">
                            <input type="checkbox" x-small color="white" v-model="getPaymentMethods[index].selected" />
                        </td> -->

              <td class="fonte">{{ item.name }}</td>
              <td class="fonte">{{ item.description }}</td>
              <td>
                <v-btn
                  small
                  outlined
                  class="text-lowercase"
                  :color="$theme.primary"
                  @click="openModalViewPaymentMethod(item)"
                >
                  visualizar</v-btn
                >
              </td>
              <td>
                <v-btn
                  small
                  outlined
                  class="text-lowercase"
                  :color="$theme.primary"
                  @click="prepagarParaApagar(item)"
                >
                  Apagar</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-list v-show="$vuetify.breakpoint.smAndDown" v-if="getPaymentMethods.length > 0" dark style="border-radius: 6px;" class="pa-0 ma-0" dense three-line>
        <template v-for="(item, index) in getPaymentMethods">
          <v-list-item @click="openModalViewPaymentMethod(item)" :key="item._id">
            <v-avatar>
              <v-icon> mdi-cash </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title> {{ item.name }} </v-list-item-title>
              <v-list-item-subtitle> {{ item.description }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>

      <Empty v-if="getPaymentMethods.length === 0"></Empty>
      <ModalViewPaymentMethod />
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalViewPaymentMethod from './components/modalViewPaymentMethod.vue'

export default {
  components: {
    ModalViewPaymentMethod
  },
  data() {
    return {
      pesquisa: "",
      options: [],
      estado: "",
      cidade: "",
      confirm_dialog: false,
      item_selecionado: false,
      contagem_selecionados: 0,
      id_to_excluse: "",
      options: [
        {
          nome: "Cadastrar",
          action: () => this.openModalViewPaymentMethod()
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getPaymentMethods", "getPaymentMethod"])
  },
  methods: {
    ...mapActions([
      "createConfirmAction",
      "listPaymentMethods",
      "deletePaymentMethod",
      "openModalViewPaymentMethod",
    ]),
    prepagarParaApagar(paymentForm) {
      this.getPaymentMethod = paymentForm
      this.createConfirmAction({
        icon: 'mdi-delete',
        message: 'Deseja remover esta forma de pagamento?',
        action: 'deletePaymentMethod',
        action_value: paymentForm
      })
    }
  },
  created() {
    this.listPaymentMethods();
  }
};
</script>
